import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import "../../styles/styles.scss"
import politicsStyles from "./politics.module.scss"
import Logo from "../../components/logo"
import "intersection-observer"
import leftCorner from "../../images/decorative-corner-left.svg"

const PoliticsPage = () => (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pl" />
        <title>Polityka prywatności | Pensjonat U Wierzby Pod Lasem</title>
        <meta name="description" content="Polityka prywatności strony Pensjonatu U Wierzby Pod Lasem."/>
        <link rel="canonical" href="https://www.uwierzby.pl/polityka-prywatnosci/" />
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={`JumbotronSection ${politicsStyles.JumbotronSection___politics}`}>
          <Jumbotron fluid className={`Jumbotron`}>
            <div className={`Jumbotron_overlay`}></div>
            <div className={`Jumbotron_logo`}>
              <Logo/>
            </div>
            <Container fluid className={`Jumbotron_container`}>
              <h1>gdpr</h1>
              <h3 className={`SectionSubTitleH3 light`}>polityka prywatności</h3>
            </Container>
          </Jumbotron>
        </section>
        <section className={`BreadcrumbSection`}>
          <Breadcrumb className={`Breadcrumb`}>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../">Strona Główna</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} active>Polityka prywatności</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section className={`MainSection`}>
          <Container fluid className={`MainSection_wrapper`}>
            <img className={`MainSection_corner___left`} src={leftCorner} alt="top left decorative corner"></img>
            <div className={`MainSection_textWrapper`}>
              <div className={`SectionTitle_wrapper`}>
                <div className={`SectionTitle_bigLetter`}><p>P</p></div>
                <h1 className={`SectionTitle`}>polityka prywatności</h1>
                <h3 className={`SectionSubTitleH3`}>GDPR</h3>
              </div>
              <div className={`MainSection_main gallery`}>
                <div className={`MainSection_mainText limited leftAlign`}>
                  <p>POLITYKA PRYWATNOŚCI I STOSOWANIA PLIKÓW "COOKIES" W SERWISIE INTERNETOWYM UWIERZBY.PL.</p>
                  <p>Gospodarstwo Agroturystyczne "Pensjonat u Wierzby pod Lasem", właściciel serwisu uwierzby.pl, dokłada wszelkich starań, aby Państwa prywatność była odpowiednio chroniona. W celu realizacji zgodnego z prawem, przejrzystego i bezpiecznego przetwarzania Państwa danych osobowych przyjmujemy niniejszą Politykę Prywatności, która obowiązuje od dnia 25 maja 2018 r.</p>
                  <p>Polityka Prywatności odwołuje się do RODO, czyli Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).</p>

                  <p>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez użytkowników w związku z korzystaniem przez nich z usług oferowanych przez serwis internetowy Pensjonat U Wierzby Pod Lasem (dalej: Serwis).</p>

                  <ol className={`${politicsStyles.upperRoman}`}>
                    <li><p>Administrator danych osobowych.</p><br/><p>Administratorem Państwa danych osobowych jest <strong>Gospodarstwo Agroturystyczne "Pensjonat u Wierzby pod Lasem" Barbara Wierzbińska</strong>, z siedzibą w: <strong>Przyłęk 137, (64-300) Nowy Tomyśl</strong>, wpisana do rejestru przedsiębiorców Centralnej Ewidencji i Informacji o Działalności Gospodarczej pod numerem <strong>NIP: 788-167-56-57</strong> (dalej: Administrator).</p></li><br/>
                    <li><p>Zakres, cele i podstawy prawne przetwarzania danych osobowych.</p><br/><p>Dane osobowe pozyskane ze strony <strong>https://www.uwierzby.pl</strong> oraz dane osobowe pozyskane w inny sposób, niż przez stronę uwierzby.pl (telefonicznie, mailowo itd.) przetwarzane są w celu:</p>
                    <ol className={`${politicsStyles.lowerAlpha}`}>
                      <li><p>Przedstawienia oferty naszych usług</p><p>Zakres danych: W celu przygotowania i przedstawienia oferty naszych usług przetwarzamy dane osobowe podane w zapytaniu: imię i nazwisko, adres e-mail, numer telefonu, treść zapytania. Podstawa prawna: Przetwarzanie jest niezbędne do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy.</p></li>
                      <li><p>Zawarcia i realizacji umowy o świadczenie usług noclegowych</p><p>Zakres danych: imię i nazwisko, adres e-mail, numer telefonu, adres zamieszkania, NIP, termin pobytu, nazwa i nr rachunku bankowego, liczba osób dorosłych objętych zamówieniem, liczba i wiek dzieci objętych zamówieniem. Podstawa prawna: umowa o świadczenie usług noclegowych.</p></li>
                      <li><p>Obsługa pozostałych zapytań. Zakres danych: W celu odpowiedzi na zapytania nie będące prośbą o przedstawienie oferty naszych usług przetwarzamy dane: imię i nazwisko, adres e-mail, numer telefonu, treść zapytania. Podstawa prawna: Nasz prawnie uzasadniony interes (przetwarzanie na żądanie osoby, której dane dotyczą).</p></li>
                      <li><p>Dochodzenia ewentualnych roszczeń przez obiekt noclegowy, w związku z poniesioną przez obiekt noclegowy szkodą wyrządzoną przez Gościa lub obrona przed roszczeniami Gościa w stosunku do obiektu noclegowego.</p></li>
                      <li><p>Udokumentowanie wykonania usługi dla celów podatkowych</p></li>
                      </ol>
                      <p>W przypadku planowanej zmiany celu przetwarzania, zawsze pytamy właściciela danych osobowych o zgodę i informujemy o zmieniających się warunkach przetwarzania danych.</p></li><br/>
                      <li><p>Pliki cookies.</p><br/>
                      <ol className={`${politicsStyles.decimal}`}>
                        <li><p>Co to są pliki cookies?</p><p>Cookies, zwane również ciasteczkami (z języka ang. cookie = ciasteczko) to niewielkie pliki tekstowe (.txt) wysyłane przez serwer WWW i zapisywane po stronie użytkownika (najczęściej na twardym dysku urządzenia). Parametry ciasteczek pozwalają na odczytanie informacji w nich zawartych jedynie serwerowi, który je utworzył. Ciasteczka są stosowane najczęściej w przypadku liczników, sond, sklepów internetowych, stron wymagających logowania, reklam i do monitorowania aktywności odwiedzających.</p></li>
                        <li><p>Jakie funkcje spełniają cookies?</p><p>Cookies zawierają różne informacje o użytkowniku danej strony WWW i historii jego łączności ze stroną. Dzięki nim właściciel serwera, który wysłał cookies, może poznać adres IP użytkownika. Pliki te pozwalają również rozpoznać podstawowe parametry urządzenia użytkownika (takie jak: typ urządzenia, rozdzielczość ekranu, kraj, z którego dochodzi do wejścia) i dzięki temu odpowiednio wyświetlić stronę internetową dostosowaną do jego potrzeb. Ponadto właściciel serwera może sprawdzić, jakiej przeglądarki używa użytkownik i czy nie nastąpiły informacje o błędach podczas wyświetlania strony. Dane te nie są kojarzone z konkretnymi osobami przeglądającymi strony, a jedynie z komputerem, na którym cookies zostało zapisane (służy do tego adres IP).</p></li>
                        <li><p>Jak wykorzystujemy informacje z cookies?</p>
                        <ol className={`${politicsStyles.lowerAlpha}`}>
                          <li><p>Wykorzystujemy pliki cookies w celu zapewnienia poprawnego działania Serwisu, a w szczególności dostosowania zawartości Serwisu do preferencji użytkownika oraz optymalizacji korzystania z serwisu.</p></li>
                          <li><p>Wykorzystujemy także cookies w celu wyświetlania mapy dojazdu przez usługę:</p></li>
                          <ul className={`${politicsStyles.ulRectangular}`}>
                            <li>Google Maps (administrator cookies: Google Inc z siedzibą w USA).</li>
                            </ul>
                            </ol>
                            </li><li><p>Rodzaje wykorzystywanych plików cookies:</p><p>W ramach Serwisu stosowane są następujące rodzaje plików „cookies":</p>
                            <ul className={`${politicsStyles.ulCircle}`}>
                              <li>sesyjne - pozostają w przeglądarce do momentu jej wyłączenia lub wylogowania się ze strony internetowej na której zostały zamieszczone;</li>
                              <li>stałe - pozostają w przeglądarce internetowej urządzenia do czasu ich usunięcia przez użytkownika bądź do z góry ustalonego czasu określonego w parametrach pliku cookies.</li></ul></li></ol><br/>
                              <p>Użytkownik może samodzielnie i w każdym czasie zmienić ustawienia dotyczące plików cookies, określając warunki ich przechowywania i uzyskiwania dostępu przez pliki cookies do urządzenia użytkownika. Zmiany ustawień można dokonać za pomocą ustawień przeglądarki internetowej. Ustawienia te mogą zostać zmienione w taki sposób, aby blokować automatyczną obsługę plików cookies bądź informować o każdorazowym zamieszczeniu cookies na urządzeniu użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach przeglądarki internetowej użytkownika.</p>
                              <ul className={`${politicsStyles.ulDisc}`}><li><a rel="nofollow" href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Chrome</a></li>
                              <li><a class="link-brown" rel="nofollow" href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Firefox</a></li>
                              <li><a rel="nofollow" href="http://windows.microsoft.com/pl-pl/windows7/block-enable-or-allow-cookies">Internet Explorer</a></li>
                              <li><a rel="nofollow" href="http://help.opera.com/Windows/12.10/pl/cookies.html">Opera</a></li>
                              <li><a rel="nofollow" href="http://support.apple.com/kb/PH5042">Safari</a></li></ul><p>Ograniczenie stosowania plików cookies może wpłynąć na niektóre funkcjonalności dostępne na stronie internetowej.</p></li><br/>
                              <li><p>Przekazywanie danych osobowych</p>
                              <ol className={`${politicsStyles.lowerAlpha}`}>
                                <li><p>Dostawcy usług</p><p>Przekazujemy Państwa dane osobowe dostawcom usług, z których korzystamy przy prowadzeniu Serwisu. W zależności od sytuacji, są oni podmiotami przetwarzającymi albo administratorami. Wykaz dostawców, z których usług korzystamy, znajdą Państwo <a href="../polityka-prywatnosci/lista-dostawcow/"><strong>tutaj</strong></a>. Wykaz jest na bieżąco aktualizowany. Podmioty przetwarzające to dostawcy usług, którzy świadczą dla nas usługę hostingu, dostarczają nam systemy do wysyłki wiadomości email i do analizy ruchu w serwisie, firmy księgowe świadczące usługi obsługi księgowej, firmy (kancelarie) prawnicze świadczące usługi doradztwa prawnego i zastępstwa procesowego, czy też firmy transportowe i taksówkarskie w sytuacji zamówienia przez Gościa transportu lub przesyłki kurierskiej.</p></li>
                                <li><p>Organy Państwowe</p><p>Udostępniamy Państwa dane osobowe, jeżeli zwrócą się do nas o to uprawnione organy państwowe. Administrator przechowuje Państwa dane przez okres nie dłuższy, niż jest to niezbędne do przygotowania spersonalizowanej oferty / odpowiedzi na inne zapytania oraz umożliwiający Administratorowi wykonanie ciążących na nim obowiązków. Przypominamy, że w każdym czasie mogą Państwo usunąć swoje dane.</p></li></ol></li>
                                <li><p>Państwa prawa związane z przetwarzaniem danych osobowych.</p><p>Przysługuje Państwu prawo do:</p>
                                <ol className={`${politicsStyles.lowerAlpha}`}>
                                  <li>dostępu do swoich danych osobowych,</li>
                                <li>sprostowania danych osobowych,</li>
                                <li>usunięcia danych osobowych,</li>
                                <li>ograniczenia przetwarzania danych osobowych,</li>
                                <li>przenoszenia danych osobowych,</li><li>wniesienia sprzeciwu do przetwarzania danych osobowych,</li>
                                <li>cofnięcia wcześniej wyrażonej zgody na przetwarzanie danych osobowych.</li></ol><p>Aby skorzystać ze swoich praw prosimy o kontakt na adres <strong >wierzba32@wp.pl</strong> używając tego samego adresu email lub numeru telefonu przekazanego do Serwisu.</p><p>Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</p></li>
                                <li><p>W Serwisie mogą pojawiać się linki do innych stron internetowych, które działają niezależnie od Serwisu i nie są w żaden sposób nadzorowane przez Serwis. Strony te mogą posiadać własne polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się uważnie zapoznać.</p></li>
                                <li><p>Administrator zastrzega sobie prawo do zmiany w polityce prywatności serwisu, co może zostać spowodowane przez rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój Serwisu. O wszelkich zmianach będziemy informować użytkowników w sposób widoczny i zrozumiały.</p></li></ol>
                </div>
              </div>
            </div>
        </Container>
      </section>
    </Layout>
  </>
);

export default PoliticsPage
